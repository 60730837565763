export default {
    title: 'Production',
    instructions: [
        {
            title: 'Part 1 - Interfaces',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 2 - Roadmap',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 3 - Communicate',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 4 - Feedback',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
    ]
}