<template>
    <div class="how_it_works h-full overflow-x-auto">
        <div class="relative i-border-1 border-secondary-two rounded ws-search-width mt-9">
            <input
                type="text"
                class="focus:outline-none py-2 pl-10 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two w-full"
                placeholder="Search"
            />
            <div class="flex items-center justify-between absolute inset-y-0 left-3 pointer-events-none">
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z" fill="var(--color-secondary-two)" />
                </svg>
            </div>
        </div>
        <div class="content_placement flex gap-30px h-540px mt-8">
            <div class="navWrapper w-217px flex flex-col justify-between">
                <NavigationBox
                    v-for="(item, index) in data" :key="item.id"
                    :active="index == activeIndex"
                    :title="item.title"
                    :index="index"
                    @onClick="onNavigate(index)"
                />
            </div>

            <div class="right_section_with_carousel w-1210px flex gap-10 bg-primary-three rounded p-30px border border-secondary-five">
                <div class="video_wrapper w-750px">
                    <div class="PlaylistBox top h-40px flex flex-wrap justify-between">
                        <PlaylistBox
                            v-for="(item, playlistIndex) in playlists"
                            :key="item.id"
                            :active="playlistIndex == activePlaylistIndex"
                            :title="item.title"
                            :index="playlistIndex"
                            @onClick="onPlayListItem(playlistIndex)"
                        />
                    </div>
                    <div class="video rounded h-424px mt-5">
                        <Player
                            :currentObj="currentObj"
                        />
                    </div>
                </div>
                <div class="carousel w-345px pt-14">
                    <Carousel :items="playlists" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import SearchBox from '@/components/workspace/SearchBox';
    import Carousel from '@/components/userJourney/carousel/Carousel.vue';
    import PlaylistBox from '@/components/userJourney/PlaylistBox';
    import Player from '@/components/userJourney/Player'
    import NavigationBox from '@/components/userJourney/NavigationBox';
    import data from '@/mock/howItWorks';
    import { ref, computed } from 'vue';
    import useCarousel from '@/components/userJourney/carousel/carousel';
    const { navigate, currentObj, items } = useCarousel();
    const activeIndex = ref(0);
    const activePlaylistIndex = ref(0);
    const playlists = computed(() => data[activeIndex.value].instructions);
    const onNavigate = (index) => {
        activeIndex.value = index;
        items.value = data[index].instructions;
        onPlayListItem(0);
    };
    const onPlayListItem = (index) => {  
        activePlaylistIndex.value = index;
        navigate(index);
    }
</script>
<style scoped>
    .ws-search-width{
        width: 100%;
        max-width: 462px;
    }
    .videoWrapper{
        box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
    }
    .videoWrapper .video{
        border: 1px solid #FFFFFF;
        filter: drop-shadow(0px 2px 8px rgba(44, 52, 65, 0.1));
    }
    .vjs-poster{
        background-color: #FFF;
    }


    /* @media all and (max-width: 1850px) and (min-width: 1300px){
        .how_it_works{
            padding-right: 20px;
        }
        .content_placement{
            flex-direction: column;
            height: 100%;
        }
        .content_placement .navWrapper{
            flex-direction: row;
            width: 100%;
            gap: 10px;
        }
        
        .right_section_with_carousel{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 350px;
            gap: 15px;
        }
        .right_section_with_carousel .video_wrapper{
            width: 100%;
        }
        .right_section_with_carousel .PlaylistBox{
            justify-content: left;
            gap: 16px;
        }
    } */
</style>