<template>
  <div v-if="items.length" class="border-t border-secondary-four">
    <div class="flex justify-between items-center pt-10">
        <img
            src="/images/icons/arrowCircleLeft.svg"
            class="cursor-pointer"
            @click="previous"
        >
      <div class="dots flex gap-3">
        <span
            v-for="(item, index) in items"
            :key="index"
            class="w-2 h-2 rounded-full bg-secondary-one cursor-pointer"
            :class="{'opacity-20' : !item.active}"
            @click="navigate(index)"
        ></span>
      </div>
      <img @click="next" class="flip cursor-pointer" src="/images/icons/arrowCircleLeft.svg" alt="">
    </div>
  </div>
</template>

<script setup>
    import useCarousel from './carousel';
    const { previous, next, navigate, items } = useCarousel();
</script>

<style scoped>
    img.flip {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
</style>