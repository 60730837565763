<template>
    <transition name="slide" mode="out-in">
        <div :key="currentObj.title" v-if="currentObj">
            <h2 class="fs-18 font-semibold mb-4"> {{ currentObj.title }} </h2>
            <p class="fs-14 font-normal" style="line-height: 22px; color: #616161">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
            <h2 class="fs-18 font-semibold mt-6">Connected in one powerfull Data Driven Interface for all!</h2>
        </div>
    </transition>
</template>

<script setup>
    import useCarousel from './carousel';
    const { currentObj } = useCarousel();
</script>

<style scoped>
.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

</style>