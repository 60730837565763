export default {
    title: 'Inspiration',
    instructions: [
        {
            title: 'Part 1 - Demo_Name',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 2 - Demo_Name',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 3 - Demo_Name',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 4 - Demo_Name',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
    ]
}