import gettingStart from './_1_gettingStart';
import setupAndConfig from './_2_setup&Config'
import teamwork from './_3_teamwork';
import production from './_4_production';
import inspiration from './_5_inspiration';
const src = {
    gettingStart,
    setupAndConfig,
    teamwork,
    production,
    inspiration,
};
const data = [];
// Object ot array
for(let item in src) {
    data.push(src[item])
}
export default data
