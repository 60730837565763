<template>
    <div class="flex flex-col h-full overflow-x-hidden">
        <Header />
        <Body />
        <Footer class="mt-auto" />
    </div>
</template>

<script setup>
    import Header from './Header';
    import Body from './Body';
    import Footer from './Footer';
    import { onMounted } from 'vue';
    import useCarousel from './carousel';
    const { load } = useCarousel();
    const props = defineProps({
        items: Array
    })
    onMounted(() => {
        load(props.items)
    })

</script>