export default {
    title: 'Setup & Config.',
    instructions: [
        {
            title: 'Part 1 - Interfaces',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/SetupConfig+interfaces.mp4',
            poster: ''
        },
        {
            title: 'Part 2 - Configuration',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 3 - Properties',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 4 - Dashboard',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
    ]
}