import { ref } from 'vue';
import { findIndex } from 'lodash';
const data = [];
const currentObj = ref({
    url: ''
});
const items = ref(data);
const activeIndex = ref(0);

export default function useCarousel() {
    currentObj.value = items.value[activeIndex.value];
    const load = data => {
        items.value = data.map((item, index) => {
            item.active = index === 0;
            item.checked = index === 0
            return item;
        });
        currentObj.value = items.value[activeIndex.value];
    }
    const next = () => {
        let currentIndex = findIndex(items.value, 'active');
        let nextIndex = ++currentIndex % items.value.length;
        activeItem(nextIndex);
    }
    const previous = () => {
        let currentIndex = findIndex(items.value, 'active');
        let previousIndex = --currentIndex % items.value.length;
        if(previousIndex < 0) {
            previousIndex = items.value.length - 1;
        }
        activeItem(previousIndex);
    };
    const navigate = currentIndex => {
      return activeItem(currentIndex)
    };
    const activeItem = index => {
        currentObj.value = items.value[index];
        items.value.forEach(item => {
            item.active = false;
            item.checked = false;
        });

        items.value[index].active = true;
        for(let i = 0; i <= index; i++) {
            items.value[i].checked = true;
        }
        activeIndex.value = index;
    }
    return {
        load,
        next,
        previous,
        navigate,
        items,
        currentObj,
        activeIndex
    }
}