<template>
        <div class="h-full w-full">
            <div v-if="poster" class="poster flex justify-center items-center">
                <img @click="onPlay" class="cursor-pointer" src="/images/icons/play_circle.svg" alt="">
            </div>
            <Video v-else ref="element" :url="url" />
        </div>
</template>

<script setup>
    import Video from '@/elements/media/Video.vue';
    import { ref, nextTick, computed, watch } from 'vue';
    const props = defineProps({
        currentObj: Object
    });
    const poster = ref(true);
    const element = ref(null);
    const url = computed(() => {
        if(props.currentObj) {
            return props.currentObj.url;
        }
    });
    watch(url, (value) => {
        if(!poster.value) {
            element.value.player.src(value);
            element.value.player.play();
        }
    });
    const onPlay = () => {
        poster.value = false;
        nextTick(() => {
            element.value.player.play();
            element.value.player.src(url.value);
        });
    }

</script>

<style scoped>
    .poster {
        height: 100%;
        width: 100%;
        border: 1px solid #FFFFFF;
        filter: drop-shadow(0px 2px 8px rgba(44, 52, 65, 0.1));
        border-radius: 4px;
    }
</style>