export default {
    title: 'Teamwork',
    instructions: [
        {
            title: 'Part 1 - Interfaces',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 2 - Scenario Logic',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 3 - Features',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
        {
            title: 'Part 4 - Collaboration',
            url: 'https://instrat360-dev.s3.eu-central-1.amazonaws.com/videos/Instrat360+Introduction.mp4',
            poster: ''
        },
    ]
}