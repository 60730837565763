<template>
  <div class="flex gap-9 mb-6">
    <div
        class="w-4 h-4 rounded-full border-2"
        v-for="(item, index) in items"
        :key="index"
        :class="[item.checked ? 'border-primary-four' : 'border-secondary-four']"
    ></div>
  </div>
</template>

<script setup>
    import useCarousel from './carousel';
    const { items } = useCarousel();
</script>
